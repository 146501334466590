import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { AuthProvider } from './contexts/AuthContext';
import { SessionTimeOut } from './components/custom/SessionTimeout';
import { LogisticsProvider } from './contexts/LogisticsContext';
// ----------------------------------------------------------------------

export default function App() {
  return (
    <AuthProvider>
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider>
            <LogisticsProvider>
              <ScrollToTop />
              <StyledChart />
              <Router />
              <SessionTimeOut />
            </LogisticsProvider>
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </AuthProvider>
  );
}
