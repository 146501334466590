import { Helmet } from 'react-helmet-async';
import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// @mui
import { useTheme } from '@mui/material/styles';
import { Container, Stack, Typography, Button, FormControl, Modal} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// components
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box';

import AllOrders from '../components/all-orders/allOrders';
import SellModal from '../components/modals/sellModal';
import BuyModal from '../components/modals/buyModal';
import ApprovedOrders from '../components/approved-orders/approvedOrders';
import DeliveredOrders from '../components/delivered-orders/delivered';
import DeclinedOrders from '../components/declined-order/declinedOrders';
import AggregatedStatsComponent from '../components/aggregated-stats-comppnent/aggregatedStatesComponent';
import AllocatedOrders from '../components/allocated-orders/allocatedOrders';
import CustomTextField from '../components/custom/CustomTextField';
import { useAlert } from '../contexts/LogisticsContext';


// ----------------------------------------------------------------------
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function LogisticsPage() {

  // Check last selected tab or default to the first order tab
  const selectedTab = Number(localStorage.getItem('logistics-tab') || 0);
  const [value, setValue] = React.useState(selectedTab);

  const [filters, setFilters] = React.useState('');
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [openFilters, setOpenFilters] = React.useState(false)
  const [updateAllOders, setUpdateAllOders] =  React.useState(false)

  const { updateStats, setUpdateStats } = useAlert();

  const handleChange = (event, newValue) => {
    setUpdateStats(true);
    setValue(newValue);
    localStorage.setItem('logistics-tab', newValue);
  };

  const changeFilters = (event, filter, newValue) => {
    
    switch(filter){
      case 'start':
        {
           const temp = filters.concat('?time_start=', moment(newValue).format('YYYY/MM/DD HH:mm:ss') )
          setFilters(temp)
        };
        break;
        case 'end':
          {
            const temp = filters.concat('&time_end=', moment(newValue).format('YYYY/MM/DD HH:mm:ss'))
            setFilters(temp)
          };
          break;
        case 'search':
          {
            const temp = filters.concat('?search=', newValue)
            setFilters(temp)
          };
          break;
          default :
          {
            setFilters('')
          }

    }
    console.log('The filters: ',filters)
  }

  const setFinalFilters = () => {
     localStorage.setItem('filters', '')
    const temp = '?'
    const finalTemp = temp.concat('start_time=', startDate, '&end_time=', endDate, '&search=', search)
     localStorage.setItem('filters', finalTemp)
    setFilters(finalTemp)
  }

  const submitFilter = (currentValue) => {
    setOpenFilters(false)
     localStorage.setItem('current', currentValue)
    setFinalFilters()
    // window.location.reload()
    // console.log("this: ", localStorage.getItem('current'))
    setValue(parseInt(localStorage.getItem('current'), 2))
 
   // setValue(currentValue)
  }

  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title> Logistics</title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Logistics
        </Typography>

        <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Orders" {...a11yProps(0)} />
          <Tab label="Approved" {...a11yProps(1)} />
          <Tab label="Delivered" {...a11yProps(2)} />
          <Tab label="Allocated" {...a11yProps(3)} />
          <Tab label="Declined" {...a11yProps(4)} />
        </Tabs>
      </Box>

      <Stack>
      <AggregatedStatsComponent updateStats={updateStats} setUpdateStats={setUpdateStats} />
      {value === 0 &&( <Grid container alignItems={'right'}>
        
        <Grid item xs={2}>
          <BuyModal modalState setUpdateStats={setUpdateStats} setUpdateAllOders={setUpdateAllOders} />
        </Grid>

        <Grid item xs={2}>
        <SellModal modalState setUpdateStats={setUpdateStats} setUpdateAllOders={setUpdateAllOders} />
        </Grid>

      </Grid>)}
      
      <Modal
              open={openFilters}
              onClose={(e)=>{setOpenFilters(false)}}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
                <Box sx={style}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePicker label="Start Date" onChange={(e)=>{setStartDate(moment(e.$d).format('YYYY/MM/DD HH:mm:ss'))}}/>
      </DemoContainer>
    </LocalizationProvider>

    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePicker label="End Date" onChange={(e)=>{setEndDate(moment(e.$d).format('YYYY/MM/DD HH:mm:ss'))}}/>
      </DemoContainer>
    </LocalizationProvider>

    <FormControl fullWidth>
          <CustomTextField id="search" label="search" variant="outlined"  sx={{ mt: 1 }} onChange={(e)=>{setSearch(e.target.value)}}/>
        </FormControl>

        <FormControl>
          <Button  variant='contained' sx={{ mt: 2, ml: 1 }} onClick={(e) => {submitFilter(value)}}>Search</Button>
        </FormControl>
    
                </Box>
      </Modal>
      </Stack>

      <CustomTabPanel value={value} index={0}>
        <AllOrders filterString={localStorage.getItem('filters')}
          updateAllOders={updateAllOders}
          setUpdateAllOders={setUpdateAllOders}
          setUpdateStats={setUpdateStats}
        />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <ApprovedOrders filterString = {localStorage.getItem('filters')} setUpdateStats={setUpdateStats} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
       <DeliveredOrders filterString = {localStorage.getItem('filters')} setUpdateStats={setUpdateStats} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
      <AllocatedOrders filterString = {localStorage.getItem('filters')} setUpdateStats={setUpdateStats} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={4}>
        <DeclinedOrders filterString = {filters} setUpdateStats={setUpdateStats} />
      </CustomTabPanel>

    </Box>

      </Container>
    </>
  );
}
