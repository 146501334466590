import axios from 'axios';
import { getBearerToken } from 'src/utils/authUtil';

const primaryBaseURL = process.env.REACT_APP_API_URL_NGROK;
const fallbackBaseURL = process.env.REACT_APP_API_URL_LOCAL;
const environment = process.env.NODE_ENV;

const API = axios.create({
    baseURL: primaryBaseURL,
    // baseURL: "https://honeybadger.ninja/api/v1",
    headers: {
        'Content-Type': 'application/json',
        'api-secret-key': process.env.REACT_APP_API_SECRET_KEY
    },
    withCredentials: true
});

API.interceptors.request.use(
  (config) => {
    const token = getBearerToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.code === 'ERR_NETWORK' && error.config && !error.config.__isRetryRequest
      && environment === 'development'
    ) {
      error.config.__isRetryRequest = true;
      console.warn('Network error, retrying with fallback baseURL...');
      error.config.baseURL = fallbackBaseURL; 
      return API(error.config); 
    }
    console.error('Response error:', error);
    return Promise.reject(error);
  }
);

export default API;
