import { Helmet } from 'react-helmet-async';
import React, { useState } from 'react';
import { OrganizationChart } from 'primereact/organizationchart';
// @mui
import { Grid, Button, Container, Stack, Typography } from '@mui/material';


// ----------------------------------------------------------------------

export default function Organogram() {
  const [data] = useState([
    {
        label: 'Non-Executive Chairperson - Thato Kgwaadira' ,
        expanded: true,
        children: [
            {
                label: 'Executive Chief Officer - Romeo Kgwaadira',
                expanded: true,
                children: [
                    {
                        label: 'Chief Operating Officer - Innocent',
                        children: [
                          {
                            label: 'Operations'
                          },
                          {
                            label: 'Business Development'
                          }
                        ]
                    },
                    {
                        label: 'Chief Financial Officer - Lesley Letsogile'
                    },
                    {
                        label: 'Depot Manger - Aubrey',
                        children: [
                          {
                            label: 'Logistics'
                          },
                          {
                            label: 'Dispatch'
                          },
                          {
                            label: 'Security'
                          }
                        ]
                    }
                ]
            }
        ]
    }
]);
  return (
    <>
      <Helmet>
        <title> Organogram  </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Organogram
          </Typography>
          
        </Stack>

        <div className="card overflow-x-auto">
            <OrganizationChart value={data} />
        </div>

        
      </Container>
    </>
  );
}
