import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';

import API from '../../../data/api';
import CustomTextField from '../../../components/custom/CustomTextField';

function ForgotPassword({ open, handleClose }) {

  const requestErrorMsg = 'Request for password reet failed - please try again later';

  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);

  const [requestError, setRequestError] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  const handleCancel = () => {
    setEmail('')
    setSubmitted(false);
    setRequestError(false);
    setEmailError(false);
    handleClose();
  }

  // Handle form submission
  const handleSubmit = async () => {
    setSubmitted(true);

    if (emailError || !email) {
      setEmailError(true);
      setSubmitted(false);
      return;
    }

    try {
      // Send the POST request with JSON body
      const response = await API.post('auth/reset-password', {
        email_address: email,
      });
      setSubmitted(false);
      if (response.status === 200) {
        // Reset the form if response is successful
        setEmail('');
        handleCancel()
      } else {
        handleRequestError(response.data);
      }
    } catch (error) {
      handleRequestError(error);
    }
  };

  const handleRequestError = (message) => {
    setSubmitted(false);
    setRequestError(true);
    console.error(message);
  }


  const handleChange = (event) => {
    const { value } = event.target;
    const validEmail = event.target.validity.valid;

    console.log(value, validEmail);
    setEmail(value);
    setEmailError(!validEmail);

  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      PaperProps={{
        component: 'form',
        noValidate: true,
        onSubmit: (event) => {
          event.preventDefault();
          handleSubmit();
        },
      }}
    >
      <DialogTitle>Reset password</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
      >
        <DialogContentText>
          Enter your account&apos;s email address, and we&apos;ll send you a link to
          reset your password.
        </DialogContentText>
        <CustomTextField
          required
          id="email"
          name="email"
          label="Email address"
          placeholder="Email address"
          type="email"
          fullWidth
          value={email}
          onChange={handleChange}
          error={emailError}
          helperText={emailError? 'Please enter a valid email address': ''}
        />
        {requestError &&
          <p style={{ color: 'red', marginTop: 10 }}> {requestErrorMsg} </p>
        }
      </DialogContent>

      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleCancel}
        >
          Cancel
        </Button>

        <LoadingButton
          color="secondary"
          variant="contained"
          type="submit"
          loading={submitted}
        >
          Continue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

ForgotPassword.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ForgotPassword;
