import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@mui/material/Modal';
import { Grid, Stack, Typography, Button, FormControl, MenuItem } from '@mui/material';
import API from '../../data/api';
import CustomTextField from '../custom/CustomTextField';
import CustomBox from '../custom/CustomBox';

export default class BuyModal extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            clients: [],
            products: [],
            productList: [],
            product: '',
            volume: '',
            client: '',
            openSell: false,
            openCheckModal: false,
            alert: '',
        }
    }


    componentDidMount (){
      this.getSuppliers()
      this.getProducts()
    }

    

    handleChange (e){
        this.setState({
            client: e.target.value
        })
    }

    handleChangeProducts (e){
        this.setState({
            product: e.target.value
        })
    }

    getSuppliers = async () =>{
        API.get('inventory/backoffice/suppliers')
        
        .then(res => {
            if(res.status === 200){
                const entries = res.data.clients
                this.setState({
                    clients: entries
                 })
                
            } else {
                console.log("There was an error retrieving suppliers")
            }
        })
        .catch((error) => {
            console.error('There was an error retrieving suppliers, ', error);
        });
    }

    getProducts = async () => {
        API.get('inventory/backoffice/products')
        .then(res => {
            if(res.status === 200){
                const entries = res.data.products
                this.setState({
                    products: entries
                 })
                
            } else {
                console.log("There was an error retrieving products")
            }
        })
        .catch((error) => {
            console.error('There was an error retrieving products, ', error);
        });
    }

    submitOrder = async (e) =>{
        const endpoint = 'inventory/backoffice/supplier_order/'
        const final = endpoint.concat(this.state.client)
        API.post(final, this.state.productList)
        .then(res => {
            if(res.status === 201){
                this.setState({
                    openSell: false
                })
                this.props.setUpdateStats(true);
                this.props.setUpdateAllOders(true);
                // window.location.reload()
            } else {
                this.setState({
                    alert: 'There was an error processing your order.'
                })
            }
        })
        .catch((error) => {
            console.error('There was an error submitting your order, ', error);
        });
    }

    openModal (value) {
        this.setState({
            openSell: value
        })
    }

    addToList (e, product, volume) {
        if(this.state.productList.includes(product)){
            console.log('it contains it')
        } else {
            this.state.productList.push({
                'product': product,
                'volume': volume
            })
            this.setState({
                product: '',
                volume: ''
            })
        }
    }

    removeFromList(e, entry, index) {
        this.state.productList.splice(index, 1)
        this.setState({})
    }

    render(){
        return(
         <Stack>
          <Button onClick={(e)=>{this.openModal(this.props.modalState)}}  variant='outlined'  sx={{ mb: 5, mr: 2}}>Buy Order</Button>
               <Modal
        open={this.state.openSell}
        onClose={(e)=>{this.openModal(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomBox>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Buy Bulk Order
          </Typography>
          <Stack id="modal-modal-description" >
          <Typography sx={{ mt: 2 }}>
            Bulk Purchase
          </Typography>
          
          {/* <FormControl fullWidth sx={{my: 2}}>
             <InputLabel id="demo-simple-select-label">Supplier</InputLabel> */}
          <CustomTextField sx={{my: 2}}
              select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.client}
              label="Supplier"
            onChange={(e)=>{this.handleChange(e)}}
          >
              {this.state.clients.map((entry)=>(
                <MenuItem key={entry.company_id} value={entry.company_id}>{entry.company_name}</MenuItem>
              ))}
          </CustomTextField>
          {/* </FormControl> */}
        
        <Grid display={this.state.productList != 0 ? 'block' : 'none'} container alignItems={'center'} sx={{ my: 1 }}>
          {this.state.productList.map((entry, index)=>(
            <Grid container alignItems={'right'} key={"product-" + index} sx={{ my: 1 }}>
            <Grid item xs={4} value={entry.product}>{entry.product}</Grid>
            <Grid item xs={4} value={entry.volume}> {entry.volume}</Grid>
            <Grid item xs={4}>
            <Button  variant='contained' onClick={(e) => {this.removeFromList(e, entry,index)}}>-</Button>
            </Grid>
           
            </Grid>
              ))}
        </Grid>


        <Grid container alignItems={'right'}>
          <Grid item xs={4}>
            <FormControl fullWidth>
             {/* <InputLabel id="demo-simple-select-label">Product</InputLabel> */}
            <CustomTextField
              select
              required
              id="product-select"
              value={this.state.product}
              label="Product"
              onChange={(e)=>{this.handleChangeProducts(e)}}>
              {this.state.products.map((entry)=>(
                <MenuItem key={entry.name} value={entry.name}>{entry.name}</MenuItem>
              ))}
           </CustomTextField>
        </FormControl>
            </Grid>
            <Grid item xs={4}>
            <FormControl fullWidth>
            <CustomTextField id="volume" label="Volume" variant="outlined" value={this.state.volume} sx={{ ml: 2 }} onChange={(e) => {this.setState({volume: e.target.value})}}/>
        </FormControl>
            </Grid>
            <Grid item xs={3}sx={{ ml: 2 }} >
        <Button  variant='contained' sx={{ mt: 2 }} onClick={(e) => {this.addToList(e, this.state.product, this.state.volume)}}>Add</Button>
            </Grid>

        </Grid>
       
        
          <Button  variant='contained' sx={{ mt: 2 }} onClick={(e) => {this.submitOrder(e)}}>Submit</Button>
          <Typography sx={{ mt: 2 }}>
            {this.state.alert}
          </Typography>
          </Stack>
          
        </CustomBox>
      </Modal>
      <Modal open={this.state.openCheckModal}>
        <p>The product you are adding has already been added, would you like to add the volume to the current order?</p>
      </Modal>
         </Stack>
        )
    }
}

BuyModal.propTypes = {
    setUpdateStats: PropTypes.func.isRequired,
    setUpdateAllOders: PropTypes.func.isRequired,
};
