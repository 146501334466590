import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Box, Drawer, Typography, Avatar, Link, Divider, MenuItem, useTheme } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import useResponsive from '../../../hooks/useResponsive';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import navConfig from './config';
import { getRealmFromIss, getRefreshToken, getUserInfo } from '../../../utils/authUtil';
import { useNavigate } from 'react-router-dom';
import API from '../../../data/api';
import { useAuth } from '../../../contexts/AuthContext';
import { StyledNavItemIcon } from 'src/components/nav-section/styles';
import SvgColor from 'src/components/svg-color';

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {

  const { logout } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  const isDesktop = useResponsive('up', 'lg');
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {

  }, [isDesktop, openNav]);

  useEffect(() => {
    const storedUserInfo = getUserInfo();
    if (storedUserInfo) {
      // const parsedUserInfo = JSON.parse(storedUserInfo);
      setUserInfo(storedUserInfo);
    }
  }, []);

  // TODO: merge with other logout method
  const handleLogout = async () => {
    try {
      const refreshToken = getRefreshToken();
      const realm = getRealmFromIss();
      
      const response = await API.post('/auth/logout', { refresh_token: refreshToken, getRealm: realm });

      if (response.status === 200) {
        // Clear local storage
        logout();

        // Navigate to login page or home page
        navigate('/login');
      } else {
        console.error('Logout failed:', response);
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2, py: 3, display: 'inline-flex' ,  mt: 5, width: "85%", }}>
        <img
          // src="https://firebasestorage.googleapis.com/v0/b/los-diesel.appspot.com/o/assets%2FLD%20Horizontal%20logo%20BLue.svg?alt=media&token=05cda179-e6bf-46fd-b78c-968183558b8e"
          alt="Honye Badger"
          src="/assets/images/hb-wordmark-light.png"
        />
      </Box>

      <Box sx={{ mb: 5, mx: 1 }}>
        <Link underline="none">
          <StyledAccount
            sx={{
              color: theme.palette.common.white,
              background: 'radial-gradient(farthest-side at 80%, #828282 0%, transparent 20%)',
              bgcolor: 'action.selected',
              fontWeight: 'fontWeightBold',
            }}
          >
            <Avatar src={userInfo.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: theme.palette.common.white }}>
                {userInfo.given_name} {userInfo.family_name}
              </Typography>
              <Typography variant="body2" sx={{ color: theme.palette.common.white }}>
                {userInfo.position || 'User'}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <MenuItem onClick={handleLogout} sx={{ ml: 0, my: 1.5, color: theme.palette.grey[500] }}>
        <StyledNavItemIcon>
          <SvgColor src={`/assets/icons/navbar/ic_logout.svg`} sx={{ width: 1, height: 1 }} />
        </StyledNavItemIcon> 
        <Typography sx={{ ml: 1 }}>
          Logout
        </Typography>
      </MenuItem>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
        color: theme.palette.common.white,
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: theme.palette.grey['A100'],
              color: theme.palette.common.white,
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          slotProps={{
            backdrop: { style: { backgroundColor: "transparent" } },
          }}
          PaperProps={{
            sx: {
              marginTop: 6,
              color: theme.palette.common.white,
              backgroundColor: theme.palette.grey['A100'],
            },
          }}
        >
          <Box
            sx={{ width: 210, }}
            onClick={onCloseNav}
          >
            {renderContent}
          </Box>
        </Drawer>
      ) }
    </Box>
  );
}
