
const storeAuthDetails = (data) => {

  localStorage.setItem('access_token', data.access_token);
  localStorage.setItem('refresh_token', data.refresh_token);
  localStorage.setItem('user_info', JSON.stringify(getUserInfo()));

  const exiryTime = getExpiryTime();
  // console.log("exiryDate: ", exiryTime.toISOString())
  localStorage.setItem('token_expiry', exiryTime?.toISOString());

}

const getLastApiCall = () => {
  return localStorage.getItem('last_api_call');
};

const getRefreshToken = () => {
  return localStorage.getItem("refresh_token");
};

const getBearerToken = () => {
  localStorage.setItem('last_api_call', new Date().toISOString());

  return getAccessToken();
};

const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

const getTokenPayload = () => {
  const tokenArray = getAccessToken()?.split(".");
  if(tokenArray){
    const tokenPayload = JSON.parse(atob(tokenArray[1]));
    // console.log("tokenPayload: ", tokenPayload)

    return tokenPayload
  }

  return null;
};

const getUserInfo = () => {
  const tokenPayload = getTokenPayload();

  // console.log("tokenPayload: ", tokenPayload)
  return tokenPayload;
};

const getElapsedTime = (referenceTime, isFuture=true) => {
  const time = isFuture? referenceTime - Date.now() : Date.now() - referenceTime;
  const minutes = (Math.floor((time / 1000 / 60) % 60));
  const seconds = (Math.floor((time / 1000) % 60));

  return {
    minutes: minutes,
    seconds: seconds,
  };
}

const getExpiryTime = () => {
  const tokenPayload = getTokenPayload();
  const tokenExpiry = tokenPayload?.exp || null;
  if (tokenExpiry) {
    return new Date(tokenExpiry * 1000);

  }
  return null;
}


const getTokenExpiry = () => {
  const exiryTime = getExpiryTime();
  if (exiryTime) {

    return getElapsedTime(exiryTime, true);
  }
  return null;
}

const isTokenExpired = () => {
  const tokenExpiry = getTokenExpiry();
  if (tokenExpiry){
    const {minutes, seconds} = tokenExpiry;
    const expired  = (minutes <= 0 && seconds <= 0);

    // return Math.floor(new Date().getTime() / 1000) >= tokenPayload?.exp;
    return expired;
  }
  return true;
}

const isUserAuthenticated = () => {
  const accessToken = getAccessToken();
  const userInfo = getUserInfo();

  const tokenExpiry = userInfo?.exp || null;
  if (accessToken && tokenExpiry){
    return !isTokenExpired();
  }
  return false;
};

const isAuthorizedRole = (roles) => {
  const userInfo = getUserInfo();
  if ( roles && userInfo) {
  // if (roles && userInfo && isUserAuthenticated()) {

    // console.log("user: ", userInfo)
    return roles.some((r) => {
      const realm = userInfo.realm_access.roles?.includes(r);
    //   const resource = user.resource_access.hasResourceRole(r);

      return realm ; //|| resource;
    });
  }
  return false;
};

const isAuthorizedRealm = (realms) => {
  const tokenPayload = getTokenPayload();
  if ( realms && tokenPayload?.iss) {
    // console.log("tokenPayload: ", tokenPayload, realms)
    return realms.includes(tokenPayload.iss.split("realms/")[1]);
  }
  return false;
};

const getLastUserInteraction = () => {
  const userInteraction = new Date(getLastApiCall() || "1900-01-01T00:00:00.000Z");
  // console.log("getLastUserInteraction", userInteraction.toISOString())
  return getElapsedTime(userInteraction, false);
}

const getRealmFromIss = () => {
  const iss = parseToken();
  if (iss) {
    const parts = iss.split('/');
    return parts[parts.length - 1];
  }

  return null;
};

const parseToken = () => {
  try {
    const token = getUserInfo();
    return token?.iss;
  } catch (error) {
    console.error('Failed to parse token:', error);
    return null;
  }
};

export {
  isUserAuthenticated,
  getAccessToken,
  getUserInfo,
  isAuthorizedRole,
  isAuthorizedRealm,
  storeAuthDetails,
  getTokenExpiry,
  getRefreshToken,
  getBearerToken,
  getLastUserInteraction,
  parseToken,
  getRealmFromIss,
}
