import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { Grid, Container, Typography, Box, Button } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import API from '../data/api';
import {
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
} from '../sections/@dashboard/app';
import CustomTextField from 'src/components/custom/CustomTextField';

export default function FinancesPage() {
  const [pieChartData, setPieChartData] = useState(null);
  const [monthlyReportData, setMonthlyReportData] = useState(null);
  const [cardData, setCardData] = useState({ approved: 0, declined: 0, delivered: 0, pastMonthRevenue: 0 });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const fetchCardData = async (timeStart = null, timeEnd = null) => {
    try {
      let endpoint = 'analytics/cards';
      const params = [];
      if (timeStart) {
        params.push(`time_start=${timeStart}`);
      }
      if (timeEnd) {
        params.push(`time_end=${timeEnd}`);
      }
      if (params.length > 0) {
        endpoint += `?${params.join('&')}`;
      }
      const cardResponse = await API.get(endpoint);
      setCardData(cardResponse.data.cards);
    } catch (error) {
      console.error('Error fetching card data:', error);
    }
  };

  const fetchMonthlyReportData = async (timeStart = null, timeEnd = null) => {
    try {
      let endpoint = 'analytics/combined_rolling_annual';
      const params = [];
      if (timeStart) {
        params.push(`time_start=${timeStart}`);
      }
      if (timeEnd) {
        params.push(`time_end=${timeEnd}`);
      }
      if (params.length > 0) {
        endpoint += `?${params.join('&')}`;
      }
      const response = await API.get(endpoint);
      setMonthlyReportData(response.data);
    } catch (error) {
      console.error('Error fetching monthly report data:', error);
    }
  };

  const fetchPieChartData = async (timeStart = null, timeEnd = null) => {
    try {
      let endpoint = 'analytics/product_revenue_pie_chart';
      const params = [];
      if (timeStart) {
        params.push(`time_start=${timeStart}`);
      }
      if (timeEnd) {
        params.push(`time_end=${timeEnd}`);
      }
      if (params.length > 0) {
        endpoint += `?${params.join('&')}`;
      }
      const pieChartResponse = await API.get(endpoint);
      setPieChartData(pieChartResponse.data);
    } catch (error) {
      console.error('Error fetching pie chart data:', error);
    }
  };

  useEffect(() => {
    fetchPieChartData();
    fetchMonthlyReportData();
    fetchCardData();
  }, []);

  const handleGenerateReport = async () => {
    const formattedStartDate = startDate ? startDate.format('YYYY-MM-DD') : '';
    const formattedEndDate = endDate ? endDate.format('YYYY-MM-DD') : '';
    const params = [];
    if (formattedStartDate) {
      params.push(`time_start=${formattedStartDate}`);
    }
    if (formattedEndDate) {
      params.push(`time_end=${formattedEndDate}`);
    }
    const endpoint = `analytics/report${params.length > 0 ? `?${params.join('&')}` : ''}`;
    
    try {
      const response = await API.get(endpoint, {
        responseType: 'blob', // Ensure the response is treated as a blob
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.xlsx'); // Change the file name and extension as needed
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error generating report:', error);
    }
  };

  const handleDateFilter = () => {
    const formattedStartDate = startDate ? startDate.format('YYYY-MM-DD') : '';
    const formattedEndDate = endDate ? endDate.format('YYYY-MM-DD') : '';
    fetchCardData(formattedStartDate, formattedEndDate);
    fetchMonthlyReportData(formattedStartDate, formattedEndDate);
    fetchPieChartData(formattedStartDate, formattedEndDate);
  };

  const formatNumber = (num) => (num !== null ? num.toFixed(2) : '0.00');

  const pieChartLabels = pieChartData ? pieChartData.data.labels : [];
  const pieChartValues = pieChartData ? pieChartData.data.datasets[0].data.map(formatNumber) : [];
  const pieChartColors = pieChartData ? pieChartData.data.datasets[0].backgroundColor : [];

  const currentDate = new Date();
  const monthlyReportLabels = monthlyReportData
    ? monthlyReportData.data.labels
    : Array.from({ length: 12 }, (_, i) => {
        const date = new Date();
        date.setMonth(currentDate.getMonth() - (11 - i));
        return date.toLocaleString('default', { month: 'short', year: 'numeric' });
      });

  const monthlyReportRevenue = monthlyReportData
    ? monthlyReportData.data.datasets[0].data.map(formatNumber)
    : [];
  const monthlyReportLossGain = monthlyReportData
    ? monthlyReportData.data.datasets[1].data.map(formatNumber)
    : [];

  const tooltipFormatter = (tooltipItem) => `${tooltipItem.value} BWP`;

  return (
    <>
      <Helmet>
        <title> Finances </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Finances
        </Typography>

        <Box sx={{ mb: 5 }} /> {/* Add spacing between the GlobalFilter and the charts */}

        <Box sx={{ mb: 5, display: 'flex', justifyContent: 'space-between' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <CustomTextField {...params} sx={{ mr: 2 }} />}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <CustomTextField {...params} sx={{ mr: 2 }} />}
            />
          </LocalizationProvider>
          <Button variant="contained" onClick={handleDateFilter}>
            Filter Data
          </Button>
          <Button variant="contained" onClick={handleGenerateReport}>
            Generate Report
          </Button>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Approved Orders" total={cardData.approved} icon={'ant-design:check-circle-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Declined Orders" total={cardData.declined} color="error" icon={'ant-design:close-circle-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Delivered Orders" total={cardData.delivered} color="warning" icon={'ant-design:car-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Revenue Expected" total={cardData.pastMonthRevenue} color="info" icon={'ant-design:shopping-filled'} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Rolling Annual Revenue/(Loss/Gain)"
              subheader="Monthly revenue tracking"
              chartLabels={monthlyReportLabels}
              chartData={[
                {
                  name: 'Revenue Generated',
                  type: 'area',
                  fill: 'solid',
                  data: monthlyReportRevenue,
                },
                {
                  name: 'Loss/Gain Revenue',
                  type: 'area',
                  fill: 'gradient',
                  data: monthlyReportLossGain,
                },
              ]}
              tooltipFormatter={tooltipFormatter}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Product Revenue"
              chartData={pieChartValues.map((value, index) => ({
                label: pieChartLabels[index],
                value: parseFloat(value), // Ensure the values are numbers
              }))}
              chartColors={pieChartColors}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
