import * as React from "react"
import {
  Box,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"

import LabeledProgressBar from "./LabeledProgressBar"

export function BulkBreakCard({ data, isTally, sx }) {

  const leftBar = {
    label: "59%",
    backgroundColor: "#000000",
    textColor: "#FFF"
  }

  const rightBar = {
    label: "41%",
    backgroundColor: "#1565c0",
    textColor: "#FFF"
  }

  const barTooltip = "There is no progress"
  return (
    <Card sx={sx}>
      <CardContent>
        <Box
          direction="column"
          sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
          spacing={3}
        >
          <Typography sx={{ mb: 1, }} variant="inherit">{data?.type || "Bulk and Bulk Break Orders"}</Typography>
          <LabeledProgressBar value={50} height={'35px'} rightBar={rightBar} leftBar={leftBar} />
          <Tooltip
            open
            arrow
            title={barTooltip}
            placement="bottom-start"
            PopperProps={{
              sx: {
                marginLeft: 10,
                '& .MuiTooltip-arrow': {
                  left: "-90px !important"
                }
              }
            }}
          >
            <Typography sx={{ marginTop: -2, }} variant="inherit" />
          </Tooltip>
        </Box>
        <Stack
          direction="row"
          sx={{
            alignItems: "flex-start",
            justifyContent: "space-between",
            mt: 6
          }}
          spacing={1}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ bgcolor: '#000000', height: '10px', width: '10px', borderRadius: '50%' }} variant="overline" />
            <Box sx={{ ml: 2, fontSize: 12 }}> Bulk Orders</Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ bgcolor: '#1565c0', height: '10px', width: '10px', borderRadius: '50%' }} variant="overline" />
            <Box sx={{ ml: 2, fontSize: 12 }}>  Bulk Break Orders</Box>
          </Box>
        </Stack>

      </CardContent>
    </Card>
  )
}
