// components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types'

import { isUserAuthenticated } from 'src/utils/authUtil';

function PrivateRoute({ element }) {
  const isAuthenticated = isUserAuthenticated();

  return isAuthenticated ? element : <Navigate to="/login" />;
}

PrivateRoute.propTypes = {
  element: PropTypes.element.isRequired,
}

export default PrivateRoute;
