import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Link, Typography } from '@mui/material';
// sections
import RegisterForm from '../sections/auth/login/RegisterForm';
// hooks
// components

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const LogoImage = styled('img')(() => ({
  marginBottom: 5,
  width: 150,
  height: 100,
  alignItems: 'center',
  alignSelf: 'center',
}));

// ----------------------------------------------------------------------

export default function RegisterPage() {
  const navigate = useNavigate();

  return (
    <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <LogoImage
                alt="Honye Badger"
                src="/assets/images/hb-logo-only-dark.png"
            />
            <Typography
              component="h1"
              variant="h4"
              sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)', textAlign: 'center', mx: 2  }}
            >
              Welcome to Honey Badger
            </Typography>
            <Typography sx={{ textAlign: 'center', m: 2 }}>
              Have an account?{' '}
              <span>
                <Link
                  sx={{ alignSelf: 'center', cursor: 'default' }}
                  variant="body2"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Log in
                </Link>
              </span>
            </Typography>
            <RegisterForm />
          </StyledContent>
        </Container>
      </StyledRoot>
  );
}
