import React, { useEffect, useState } from 'react'

import { Box, MenuItem, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/system';

import CustomTextField from './CustomTextField';
import PropTypes from 'prop-types'
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

const PaginationTypography  = styled(Typography)(({ theme }) => ({
  marginTop: '15px',
}));

const PageCountTypography  = styled(Typography)(({ theme }) => ({
  marginTop: '12px',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  height: '30px',
  width: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));


const CustomTablePagination = ({ rowsPerPage, recordsList, handleChangeRowsPerPage,
  handleNextPage, currentPage, totalPages }) => {

    const theme = useTheme();

  const [isNextDisabled, setIsNextDisabled] = useState(!totalPages || (currentPage === totalPages));
  const [nextColor, setNextColor] = useState();
  const [isPrevDisabled, setIsPrevDisabled] = useState(currentPage === 1);
  const [prevColor, setPrevColor] = useState();

  useEffect(() => {
    setIsNextDisabled(!totalPages || (currentPage === totalPages));
    setIsPrevDisabled(currentPage === 1);
    setNextColor(isNextDisabled? 'disabled': 'inherit');
    setPrevColor(isPrevDisabled? 'disabled': 'inherit');

  },[ isNextDisabled, isPrevDisabled, currentPage, totalPages, rowsPerPage])

  return (
    <Box
    component="div"

      sx={{ display: 'flex', p: 1, borderRadius: 1, marginTop: 5 }}
    >
      <Box sx={{ width: '100%' }}>
        <CustomTextField
          sx={{ width: 100 }}
          select
          required
          labelId="demo-simple-select-label"
          id="No-of-Rows"
          value={rowsPerPage}
          label="No of Rows: "
          onChange={(e) => { handleChangeRowsPerPage(e) }}
        >
          {recordsList.map((entry) => (
            <MenuItem key={entry} value={entry}>{entry}</MenuItem>
          ))}
        </CustomTextField>
      </Box>

      <Box sx={{ display: 'flex', flexShrink: 0, mr: 2 }}>
        <PaginationTypography >Page</PaginationTypography>
        <ArrowLeft
          fontSize={'large'}
          color={prevColor}
          sx={{ mt: 1.2, }}
          onClick={() => { !isPrevDisabled && handleNextPage('prev') }}
        />

        <PageCountTypography> {currentPage} </PageCountTypography>

        <Box sx={{ disabled: 'true' }}>
          <ArrowRight
            fontSize={'large'}
            color={nextColor}
            sx={{ mt: 1.2, }}
            onClick={() => { !isNextDisabled && handleNextPage('next') }}
          />
        </Box>

        {totalPages > 0 && (<PaginationTypography
          sx={{
            borderBottom: '5px solid',
            borderBottomColor: theme.palette.primary.main,
            paddingBottom: 0,
            marginBottom: 0,
            fontSize: '18px',
          }}
        >
          {totalPages}
        </PaginationTypography>
        )}
      </Box>
    </Box>
  )
}

CustomTablePagination.propTypes = {
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  recordsList: PropTypes.array.isRequired,
  handleNextPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
}

export default CustomTablePagination

