import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import API from '../../../data/api';
import { useAuth } from '../../../contexts/AuthContext';
import navConfig from '../nav/config';
import { getRealmFromIss, getRefreshToken, parseToken } from '../../../utils/authUtil'

// const MENU_OPTIONS = [
//   {
//     label: 'Home',
//     icon: 'eva:home-fill',
//     path: '/dashboard',
//   },
//   {
//     label: 'Profile',
//     icon: 'eva:person-fill',
//     path: '/dashboard/user',
//   },
//   {
//     label: 'Logistics',
//     icon: 'eva:car-fill',
//     path: '/dashboard/logistics',
//   },
//   {
//     label: 'Finance',
//     icon: 'eva:credit-card-fill',
//     path: '/dashboard/finances',
//   },
//   {
//     label: 'Reports',
//     icon: 'eva:settings-2-fill',
//     path: '/dashboard/reports',
//   },
//   {
//     label: 'Settings',
//     icon: 'eva:settings-2-fill',
//     path: '/dashboard/settings',
//   },
// ];

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();

  const { logout } = useAuth();

  useEffect(() => {
    // Fetch user_info from local storage
    const storedUserInfo = localStorage.getItem('user_info');
    if (storedUserInfo) {
      setUserInfo(JSON.parse(storedUserInfo));
    }
  }, []);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMenuClick = (path) => {
    navigate(path);
    handleClose();
  };

  const handleLogout = async () => {
    try {
      const refreshToken = getRefreshToken(); // Assuming you store the refresh token in local storage
      const realm = getRealmFromIss();
      
      const response = await API.post('/auth/logout', { refresh_token: refreshToken, getRealm: realm });

      if (response.status === 200) {
        // Clear local storage
        logout();

        // Navigate to login page or home page
        navigate('/login');
      } else {
        console.error('Logout failed:', response);
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <>
      {userInfo && (
        <Typography style={{ color: 'navy' }} variant="h4" sx={{ mb: 1 }}>
          {userInfo.given_name} {userInfo.family_name}
        </Typography>
      )}
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={userInfo ? userInfo.profilePic : ''} alt="Profile Picture" />
      </IconButton>

      <Popover
        component="nav"
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              p: 0,
              mt: 1.5,
              ml: 0.75,
              width: 180,
              '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          },
        }}
      >
        {userInfo && (
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle2" noWrap>
              {userInfo.name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {userInfo.email}
            </Typography>
          </Box>
        )}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {navConfig.map((option) => (
            <MenuItem key={option.title} onClick={() => handleMenuClick(option.path)}>
              {option.title}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
