import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Card,
  Stack,
  Container,
  Typography,
  Button
} from '@mui/material';

// ----------------------------------------------------------------------

export default function UserPage() {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    // Fetch user_info from local storage
    const storedUserInfo = localStorage.getItem('user_info');
    if (storedUserInfo) {
      setUserInfo(JSON.parse(storedUserInfo));
      // add an endpoint to get more information about the users
    }
  }, []);

  if (!userInfo) {
    return <div>Loading...</div>; // Loading state
  }

  return (
    <>
      <Helmet>
        <title>User Profile</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Industry Profile
          </Typography>
        </Stack>

        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" m={2}>
            <img
              src={userInfo.profilePic || 'https://via.placeholder.com/260x210'} // Replace with actual image URL from userInfo
              alt="profile pic"
              width={"260px"}
              height={"210px"}
            />

            <Stack direction="column" alignItems="left" justifyContent="space-between" m={3}>
              <Typography variant='h4'>
                {userInfo.name}
              </Typography>
              <Typography variant='h6'>
                {userInfo.email}
              </Typography>
              <Typography variant='h6'>
                {userInfo.phoneNumber}
              </Typography>
              <Typography variant='h6'>
                {userInfo.department}
              </Typography>
              <Typography variant='h6'>
                {userInfo.region}
              </Typography>
              <Typography variant='h6'>
                {userInfo.jobTitle}
              </Typography>
              <Stack spacing={{ xs: 1, sm: 2 }} direction="row" alignItems="left">
                <Button variant="contained">Update</Button>
                <Button variant="outlined">Manage</Button>
              </Stack>
            </Stack>
          </Stack>
        </Card>
      </Container>
    </>
  );
}
