import React from 'react';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import { Button, FormControl, MenuItem, Paper, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Modal from '@mui/material/Modal';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import PropTypes from 'prop-types';
import API from '../../data/api';
import GlobalFilter from '../global-filter/GlobalFilter'; // Import GlobalFilter
import CustomTextField from '../custom/CustomTextField';
import CustomBox from '../custom/CustomBox';
import CustomTablePagination from '../custom/CustomTablePagination';

export default class AllOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allOrders: [],
      openModal: false,
      orderID: '',
      reason: '',
      orderPo: '',
      pricePerLiter: '',
      productId: '',
      productName: '',
      orderItemID: '',
      selectedDepot: '',
      depots: [],
      page: 1,
      records: 15,
      totalRecords: 1,
      totalPages: null,
      recordsList: [5, 10, 15, 20],
      filterString: '',
      isClient: false,
      proofOfPayment: null,
      isPoPUploaded: false,
    };
  }

  componentDidMount() {
    this.getAllOrders(this.props.filterString);
    this.fetchDepots();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.filterString !== this.props.filterString || prevState?.filterString !== this.state.filterString
        || this.props.updateAllOders
    ) {
      console.log("Effective state change - reload orders, ", this.state.filterString)
      this.getAllOrders(this.state.filterString);
      this.props.setUpdateAllOders(false);
    }
  }

  forceRerender = () => {
    this.forceUpdate();
  }

  getAllOrders = async (finalQuery = '') => {
    const { records, page } = this.state;
    let endpoint = `inventory/backoffice/order_items?records=${records}&page=${page}`;
  
    try {
      if (finalQuery) {
        endpoint += finalQuery;
        console.log(`Search query string: ${endpoint}`);
      }
      // Perform the GET request
      try {
        const res = await API.get(endpoint);
        console.log(`ORDERS -> status [${res.status}]`);

        if (res.status === 200) {
          console.log(`ORDER ITEMS: ${res.status}`);
          const entries = res.data.orders;
          this.setState({
            allOrders: entries,
            totalPages: res.data.total_pages,
            totalRecords: res.data.total_orders,
          });
          this.props.setUpdateAllOders(false);
        } else {
          console.log("Error fetching all orders:", res.status);
        }
      } catch (error) {
        console.log("Error fetching all orders:", error);
      }

    } catch (error) {
      console.error('Preflight request error:', error);
    }
  };
  

  fetchDepots = async () => {
    try {
      const response = await API.get('inventory/backoffice/depots');
      this.setState({ depots: response.data.depots });
    } catch (error) {
      console.error('Error fetching depots:', error);
    }
  };

  submitOrder = async (verdict) => {
    const { orderID, orderPo, reason, pricePerLiter, selectedDepot, orderItemID, productId, proofOfPayment, isClient } = this.state;

    console.log(`orderID = ${orderID}\norderPO = ${orderPo}\nproductID = ${productId}`);

    let endpoint; 
    let conTemp;

    if (verdict === 'approve') {
        endpoint = 'inventory/backoffice/approve_order/';
        conTemp = `${endpoint}${orderID}?purchase_order=${orderPo}&order_id=${orderID}&is_client=${isClient}&reason=${reason || 'null'}&order_item_id=${orderItemID}`;
        
        if (pricePerLiter) {
            conTemp += `&price_per_liter=${pricePerLiter}`;
        }
        if (selectedDepot) {
            conTemp += `&depot_id=${selectedDepot}`;
        }
        if (productId) {
            conTemp += `&product_id=${productId}`;
        }
    } else if (verdict === 'decline') {
        endpoint = 'inventory/backoffice/decline_order/';
        conTemp = `${endpoint}${orderItemID}?purchase_order=${orderPo}&order_id=${orderID}&is_client=${isClient}&reason=${reason || 'null'}`;
    }

    try {
      const res = await API.patch(conTemp);
      if (res.status === 206) {
        this.setState({ openModal: false, selectedDepot: '', orderPo: '', reason: '', pricePerLiter: '' });
        this.props.setUpdateStats(true);
        this.props.setUpdateAllOders(true);
      } else {
        console.log("Error:", res.data);
      }
    } catch (error) {
      console.log("Error submitting order:", error);
    }
  };

  handleProofOfPaymentChange = (event) => {
    const file = event.target.files[0];
    this.setState({ proofOfPayment: file, isPoPUploaded: !!file });
  };


  nextPage = (action) => {
    const { totalPages } = this.state;
    this.setState((prevState) => {
      let currentPage = prevState.page;
      if (action === 'next') {
        currentPage = Math.min(currentPage + 1, totalPages);
      } else {
        currentPage = Math.max(currentPage - 1, 1);
      }
      return { page: currentPage };
    }, () => {
      this.getAllOrders(this.props.filterString);
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(() => {
      return { records: parseInt(event.target.value, 10), page: 1 };
    }, () => {
      this.getAllOrders(this.props.filterString);
    });
  }

  handleFilterChange = (filterString) => {
    this.setState({ filterString });
  };

  openModalWithOrder = (order) => {
    console.log(`Opening modal with order: ${JSON.stringify(order)}`);
    this.setState({
      openModal: true,
      orderID: order.order_id,
      productId: order.product_id,
      productName: order.fuel_type,
      orderItemID: order.order_item_id,
      isClient: order.is_client,
      isPoPUploaded: false, // Reset PoP uploaded state when opening modal
    });
  };

  render() {
    const { allOrders, openModal, productName, depots, isClient, isPoPUploaded } = this.state;
    return (
      <Paper>
        <GlobalFilter sx={{ my: 1 }} onFilterChange={this.handleFilterChange} />
 
        <TableContainer sx={{ mt: 2,  width: '100%', overflowX: { xs: 'scroll', sm: 'auto' } }}>

        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Order Number</TableCell>
              <TableCell align="right">Client Name</TableCell>
              <TableCell align="right">Product</TableCell>
              <TableCell align="right">Volume</TableCell>
              <TableCell align="right">Created Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allOrders.map((row) => (
              <TableRow
                key={row.order_item_id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '&:hover': { backgroundColor: 'rgba(39,226,245,0.27)' },
                }}
                onClick={() => this.openModalWithOrder(row)}
              >
                <TableCell component="th" scope="row">
                  {row.order_item_id}
                </TableCell>
                <TableCell align="right">{row.company_name}</TableCell>
                <TableCell align="right">{row.fuel_type}</TableCell>
                <TableCell align="right">{row.volume}</TableCell>
                <TableCell align="right">{moment.utc(row.created_date).format('YYYY/MM/DD - HH:mm:ss')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomTablePagination
        rowsPerPage={this.state.records}
        recordsList={this.state.recordsList}
        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        handleNextPage={this.nextPage}
        currentPage={this.state.page}
        totalPages={this.state.totalPages}
      />

        <Modal
          open={openModal}
          onClose={() => { this.setState({ openModal: false }) }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <CustomBox>
            <p>Would you like to approve or decline this order?</p>
            <FormControl fullWidth>
              <CustomTextField
                id="po"
                label="PO Number"
                variant="outlined"
                sx={{ mt: 1 }}
                value={this.state.orderPo}
                onChange={(e) => { this.setState({ orderPo: e.target.value }) }}
                disabled={!isClient && !isPoPUploaded} // Enable if isClient is true or PoP is uploaded
              />
            </FormControl>

            <FormControl fullWidth>
              <CustomTextField
                id="reason"
                label="Reason"
                variant="outlined"
                sx={{ mt: 2 }}
                value={this.state.reason}
                onChange={(e) => { this.setState({ reason: e.target.value }) }}
                disabled={!isClient && !isPoPUploaded} // Enable if isClient is true or PoP is uploaded
              />
            </FormControl>

            {isClient && (
              <>
                <FormControl fullWidth>
                  <CustomTextField 
                    name="pricePerLiter"
                    id="price-per-liter"
                    label={`${productName} price/litre`}
                    variant="outlined"
                    sx={{ mt: 1 }}
                    value={this.state.pricePerLiter}
                    onChange={(e) => { this.setState({ pricePerLiter: e.target.value }) }}
                 />
                </FormControl>

                <FormControl fullWidth sx={{ mt: 2 }}>
                  {/* <InputLabel id="depot-select-label">Select Depot</InputLabel> */}
                  <CustomTextField
                    displayEmpty
                    select
                    required
                    labelId="depot-select-label"
                    label="Select Depot"
                    value={this.state.selectedDepot}
                    onChange={(e) => this.setState({ selectedDepot: e.target.value })}
                  >
                    <MenuItem value="">
                      <em>Select Depot</em>
                    </MenuItem>
                    {depots.map((depot) => (
                      <MenuItem key={depot.depot_id} value={depot.depot_id}>
                        {depot.depot_name}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </FormControl>
              </>
            )}

            <Grid container alignItems={'center'}>
              <Grid item xs={4}>
                <Button
                  color="success"
                  variant='contained'
                  sx={{ mt: 2 }}
                  onClick={() => { this.submitOrder('approve') }}
                  disabled={!isClient && !isPoPUploaded} // Enable only if isClient is false and PoP is uploaded
                >
                  Approve
                </Button>
              </Grid>

              <Grid item xs={4}>
                <Button
                  color="error"
                  variant='contained'
                  sx={{ mt: 2 }}
                  onClick={() => { this.submitOrder('decline') }}
                  disabled={!isClient && !isPoPUploaded} // Enable only if isClient is false and PoP is uploaded
                >
                  Decline
                </Button>
              </Grid>

              {!isClient && (
                <Grid item xs={4}>
                  <Button variant='contained' sx={{ mt: 2 }} component="label">
                    PoP
                    <input type="file" hidden onChange={this.handleProofOfPaymentChange} />
                  </Button>
                </Grid>
              )}
            </Grid>
          </CustomBox>
        </Modal>
      </Paper>
    );
  }
}

AllOrders.propTypes = {
  filterString: PropTypes.string.isRequired,
  setUpdateStats: PropTypes.func.isRequired,
  updateAllOders: PropTypes.bool.isRequired,
  setUpdateAllOders: PropTypes.func.isRequired,
};
