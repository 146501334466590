// contexts/LogisticsContext.js
import React, { createContext, useContext, useState, useEffect, useMemo  } from 'react';
import PropTypes from 'prop-types'
import { useAuth } from './AuthContext';

const LogisticsContext = createContext();

export function LogisticsProvider({ children }) {
    const { isAuthenticated } = useAuth();
    const [updateStats, setUpdateStats] = useState(true);

  useEffect(() => {
    //console.log("LogisticsProvider loading with updateStats =", updateStats)
    if(!isAuthenticated){
        setUpdateStats(false);
    }
  }, [isAuthenticated, updateStats]);


  const contextValue = useMemo( () => 
    ({
        updateStats,
        setUpdateStats,
    }), [updateStats]
  );

  return (
    <LogisticsContext.Provider value= {contextValue}>
      {children}
    </LogisticsContext.Provider>
  );
}

LogisticsProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export function useAlert() {
  const context =  useContext(LogisticsContext);
  if (!context) {
    const message = "LogisticsProvider context is undefined, please verify you are calling useAlert() as child of a <LogisticsProvider> component."
    console.error(message);
    throw new Error(message);
  }

  return context;

}
