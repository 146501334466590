import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #fff',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
};

const CustomBox = ({sx ={}, ...props }) => {
  return (
    <Box
      sx={{
        ...style, ...sx,
        width: {
          xs: 300,
          sm: 400,
        }
      }}
      {...props}
    />
  )
}

CustomBox.propTypes = {
  sx: PropTypes.object,
  props: PropTypes.any,
}

export default CustomBox