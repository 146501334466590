import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
// @mui
import { Grid, Button, Container, Stack, Typography, MenuItem, Box } from '@mui/material';
import API from '../data/api';
import CustomTextField from '../components/custom/CustomTextField';

export default function SettingsPage() {
  const [departments, setDepartments] = useState([]);
  const [regions, setRegions] = useState([]);
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    phoneNumber: '',
    department: '',
    region: '',
    jobTitle: '',
  });

  useEffect(() => {
    // Retrieve user info from localStorage
    const storedUserInfo = JSON.parse(localStorage.getItem('user_info'));
    if (storedUserInfo) {
      setFormValues({
        firstName: storedUserInfo.given_name || '',
        lastName: storedUserInfo.family_name || '',
        username: storedUserInfo.preferred_username || '',
        email: storedUserInfo.email || '',
        phoneNumber: storedUserInfo.phone_number || '',
        department: '', // Set based on your logic, as department info might not be in the token
        region: '',     // Set based on your logic, as region info might not be in the token
        jobTitle: storedUserInfo.position || '',
      });
    }

    // Fetch departments and regions
    const fetchDropdownData = async () => {
      try {
        const departmentResponse = await API.get('settings/department', {
          params: { department: '' },
          headers: {
            'accept': 'application/json',
            'api-secret-key': 's3cr3t',
          }
        });
        const regionResponse = await API.get('settings/region', {
          params: { region: '' },
          headers: {
            'accept': 'application/json',
            'api-secret-key': 's3cr3t',
          }
        });
        console.log(`Departments: ${JSON.stringify(departmentResponse.data)}`);
        console.log(`Regions: ${JSON.stringify(regionResponse.data)}`);
        
        setDepartments(departmentResponse.data.departments);
        setRegions(regionResponse.data.regions);
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };

    fetchDropdownData();
  }, []);

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Save changes
      await API.post('settings/user', formValues);
      alert('User information saved successfully!');
    } catch (error) {
      console.error('Error saving user information:', error);
    }
  };

  const handleCancel = () => {
    // Reset form values
    setFormValues({
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      phoneNumber: '',
      department: '',
      region: '',
      jobTitle: '',
    });
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Settings </title>
      </Helmet>

      <Container>
        <Typography variant="h4" gutterBottom>
          User Settings
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                required
                fullWidth
                label="First Name"
                name="firstName"
                value={formValues.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                required
                fullWidth
                label="Last Name"
                name="lastName"
                value={formValues.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                required
                fullWidth
                label="Username"
                name="username"
                value={formValues.username}
                onChange={handleChange}
                disabled={Boolean(formValues.username)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                required
                fullWidth
                label="Email Address"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                disabled={Boolean(formValues.email)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                required
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                value={formValues.phoneNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                select
                required
                fullWidth
                label="Department"
                name="department"
                value={formValues.department}
                onChange={handleChange}
              >
                {departments.map((department) => (
                  <MenuItem key={department.id} value={department.id}>
                    {department.name}
                  </MenuItem>
                ))}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                select
                required
                fullWidth
                label="Region"
                name="region"
                value={formValues.region}
                onChange={handleChange}
              >
                {regions.map((region) => (
                  <MenuItem key={region.id} value={region.id}>
                    {region.name}
                  </MenuItem>
                ))}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                required
                fullWidth
                label="Position/Job Title"
                name="jobTitle"
                value={formValues.jobTitle}
                onChange={handleChange}
                disabled={Boolean(formValues.jobTitle)}
              />
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 3 }}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save Changes
            </Button>
          </Stack>
        </Box>
      </Container>
    </>
  );
}
