import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import SettingsPage from './pages/SettingsPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ReportsPage from './pages/Reports';
import DashboardAppPage from './pages/DashboardAppPage';
import RegisterPage from './pages/RegisterPage';
import LogisticsPage from './pages/logistics';
import Organogram from './pages/organogram';
import FinancesPage from './pages/finance';
import PrivateRoute from './components/private-route/PrivateRoute';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <PrivateRoute element={<DashboardLayout /> } />,
      children: [
        { element: <PrivateRoute element={<Navigate to="/dashboard/app" />} />, index: true },
        { path: 'app', element: <PrivateRoute element={<DashboardAppPage />} /> },
        { path: 'user', element: <PrivateRoute element={<UserPage />} /> },
        { path: 'reports', element: <PrivateRoute element={<ReportsPage />} /> },
        { path: 'logistics', element: <PrivateRoute element={<LogisticsPage />} /> },
        { path: 'organogram', element: <PrivateRoute element={<Organogram />} /> },
        { path: 'finances', element: <PrivateRoute element={<FinancesPage />} /> },
        { path: 'settings', element: <PrivateRoute element={<SettingsPage />} /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}