// contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect, useMemo  } from 'react';
import PropTypes from 'prop-types'

import {
  isUserAuthenticated,
  isAuthorizedRole,
  isAuthorizedRealm, 
  getUserInfo, 
  storeAuthDetails, 
} from '../utils/authUtil';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const activeSession = isUserAuthenticated();
  const [isAuthenticated, setIsAuthenticated] = useState(activeSession);
  const [userInfo, setUserInfo] = useState(getUserInfo());

  useEffect(() => {
    setIsAuthenticated(activeSession);
    if (!activeSession){
      logout(); // clear localstorage
    }
  }, [activeSession]);

  const login =(authDetails) => {
    storeAuthDetails(authDetails.user_info?.access_token || authDetails);
    const activeSession = isUserAuthenticated();
    setIsAuthenticated(activeSession);
    setUserInfo(getUserInfo());
    localStorage.setItem('isAuthenticated', activeSession);
  };

  const logout = () => {
    localStorage.clear();
    setIsAuthenticated(false);
    setUserInfo(null);
  };


  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      userInfo,
      login,
      logout,
    }),
    [userInfo, isAuthenticated]
  );

  return (
    <AuthContext.Provider value= {contextValue}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export function useAuth() {
  const context =  useContext(AuthContext);
  if (!context) {
    const message = "AuthProvider context is undefined, please verify you are calling useAuth() as child of a <AuthProvider> component."
    console.error(message);
    throw new Error(message);
  }

  return context;

}
