import { useState } from 'react';
import { Stack, MenuItem } from '@mui/material';
import API from '../../../data/api';
import CustomTextField from '../../../components/custom/CustomTextField';
import { initialiseForm, updateFormError, updateFormValue, validateForm } from '../../../utils/formUtil';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function RegisterForm() {

  // Initial state for form fields
  const initialFormData = {
    name: { value: '', required: true },
    surname: { value: '', required: true },
    email: { value: '', required: true },
    country: { value: "Botswana", required: true },
    cellphone: { value: '', required: true },
    companyName: { value: '', required: true },
    position: { value: '', required: true },
    additionalInfo: { value: '', required: false },
  };
  const initFormState = initialiseForm(initialFormData);
  const registerErrorMessage = 'Registration failed - please try again later';

  const [registerError, setRegisterError] = useState('');
  const [formData, setFormData] = useState(initFormState);
  const [submitted, setSubmitted] = useState(false);

  // Handle input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    const isEmail = name === "email";
    let validEmail = false;
    const newFormData = updateFormValue(setFormData, formData, name, value, !isEmail);

    if (isEmail) {
      validEmail = event.target.validity.valid;
      console.log("isEmail", value, validEmail);
      updateFormError(setFormData, newFormData, name, !validEmail);
    }

  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);

    if(validateForm(setFormData, formData)){
      setSubmitted(false);
      return;
    }

    try {
      const countryCodeMap = {
        "South Africa": "+27",
        "Namibia": "+264",
        "Botswana": "+267",
        "Mozambique": "+258",
        "Zimbabwe": "+263",
      };
  
      // Retrieve the country code based on the selected country
      const getCode = countryCodeMap[formData.country.value] || '';
  
      // Concatenate the phone number with the country code
      const phoneWithCode = `${getCode}${formData.cellphone.value}`;
  
      // Log the formatted phone number for debugging
      console.log(phoneWithCode);
      
      console.log(formData);

      // Send the POST request with JSON body
      const response = await API.post('auth/registration', {
        first_name: formData.name.value,
        last_name: formData.surname.value,
        email_address: formData.email.value,
        phone_number: phoneWithCode,
        company_name: formData.companyName.value,
        position: formData.position.value,
        additional_comments: formData.additionalInfo?.value || '',  // Handle optional field
      });

      // console.log(`/auth/registration status code: ${response.status}`);
  
      setSubmitted(false);
      if (response.status === 200) {
        // Reset the form if response is successful
        setFormData(initialFormData);
      } else {
        handleRegisterError(response.data);
      }
    } catch (error) {
      handleRegisterError(error);
    }
  };

  const handleRegisterError = (message) => {
    setSubmitted(false);
    setRegisterError(true);
    console.error(message);
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Stack spacing={3}>
        <CustomTextField
          name="name"
          label="First Name"
          value={formData.name.value}
          onChange={handleChange}
          required={formData.name.required}
          error={formData.name.error}
          helperText={formData.name.error && formData.name.errorMessage}
       />
        <CustomTextField
          name="surname"
          label="Last Name"
          value={formData.surname.value}
          onChange={handleChange}
          required={formData.surname.required}
          error={formData.surname.error}
          helperText={formData.surname.error && formData.surname.errorMessage}
        />
        <CustomTextField
          name="email"
          label="Email address"
          type="email"
          value={formData.email.value}
          onChange={handleChange}
          required={formData.email.required}
          error={formData.email.error}
          helperText={formData.email.error && formData.email.errorMessage}
        />
        
        <Stack direction="row" spacing={2}>
          {/* <FormControl> */}
            {/* <InputLabel >Country Code</InputLabel> */}
            <CustomTextField
              select
              name="country"
              label="Country Code"
              value={formData.country.value}
              onChange={handleChange}
              required={formData.country.required}
              error={formData.country.error}
              helperText={formData.country.error && formData.country.errorMessage}
              sx={{ width: '75%' }}
            >
              <MenuItem value="Botswana">Botswana (+267)</MenuItem>
              <MenuItem value="Namibia">Namibia (+264)</MenuItem>
              <MenuItem value="Mozambique">Mozambique (+258)</MenuItem>
              <MenuItem value="South Africa">South Africa (+27)</MenuItem>
              <MenuItem value="Zimbabwe">Zimbabwe (+263)</MenuItem>
            </CustomTextField>
          {/* </FormControl> */}
          <CustomTextField
            fullWidth
            name="cellphone"
            label="Cellphone Number"
            value={formData.cellphone.value}
            onChange={handleChange}
            required={formData.cellphone.required}
            error={formData.cellphone.error}
            helperText={formData.cellphone.error && formData.cellphone.errorMessage}
          />
        </Stack>

        <CustomTextField
          name="companyName"
          label="Company Name" 
          value={formData.companyName.value}
          onChange={handleChange}
          required={formData.companyName.required}
          error={formData.companyName.error}
          helperText={formData.companyName.error && formData.companyName.errorMessage}
        />

        <CustomTextField
          name="position"
          label="Position"
          value={formData.position.value}
          onChange={handleChange}
          required={formData.position.required}
          error={formData.position.error}
          helperText={formData.position.error && formData.position.errorMessage}
        />
        
        <CustomTextField
          name="additionalInfo"
          label="Additional Information / Comment"
          multiline
          rows={4}
          value={formData.additionalInfo.value}
          onChange={handleChange}
          required={formData.additionalInfo.required}
          error={formData.additionalInfo.error}
          helperText={formData.additionalInfo.error && formData.additionalInfo.errorMessage}
        />

        {registerError &&
          <p style={{ color: 'red', marginTop: 15 }}> {registerErrorMessage} </p>
        }

        <LoadingButton
          sx={{ mr: 2, backgroundColor: '#3B3297' }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={submitted}
        >
          Register
        </LoadingButton>
      </Stack>
    </form>
  );
}
