import * as React from 'react';
import Grid from '@mui/material/Grid2';
import { TallyCard } from '../components/dashboard/cards/TallyCard';
import { ProductCard } from '../components/dashboard/cards/ProductCard';
import { ProgressCard } from '../components/dashboard/cards/ProgressCard';
import { BulkBreakCard } from '../components/dashboard/cards/BulkBreakCard';

const data = {
  type: 'Revenue Total',
  value: 'R32 m',
  color: "#d32f2f",
  metadata: {
    ulp93: 50,
    ulp95: 20,
    ppm50: 100,
  }
}

const excoData = {
  type: 'Trucks Enroute',
  value: 'R32 m',
  color: '#4caf50',
  metadata: {
    ulp93: 13,
    ulp95: 5,
    ppm50: 24,
  }
}

const another = {
  type: 'Revenue Total',
  value: 'R56 m',
  color: '#ed6c02',
  metadata: {
    ulp93: 50,
    ulp95: 20,
    ppm50: 100,
  }
}

const something = {
  type: 'Another Total',
  value: 'R105 m',
  color: '#1976d2',
  metadata: {
    ulp93: 50,
    ulp95: 20,
    ppm50: 100,
  }
}

const without = {
  type: 'Total Opex',
  value: 'R105 m',
  color: '#4caf50',
}

const uprogress = {
  type: 'Number1 Progress',
  value: 75.5,
  metadata: {
    trend: 'up',
    indicator: '10 something last',
    progress: 45,
    diff: 22,
  }
}

const dprogress = {
  type: 'Another Progress',
  value: 62.8,
  metadata: {
    trend: 'down',
    indicator: '6 wows',
    progress: 78,
    diff: -15,
  }
}

const iprogress = {
  type: 'Test Progress',
  value: 62.8,
  metadata: {
    indicator: '10 something last',
    progress: 78,
    diff: 8,
  }
}

const nsuborders = {
  type: 'Total Suborders',
  value: 15,
  color: '#4caf50',
}

const osuborders = {
  type: 'Total Suborders',
  value: 10,
  color: '#ed6c02',
}

const dsuborders = {
  type: 'Total Suborders',
  value: 15,
  color: '#1976d2',
}

const exData = [data, something, excoData, another]
const opData = [without, data, something, another]
const progData = [uprogress, dprogress, uprogress, iprogress]
const suborders = [nsuborders, osuborders, dsuborders]
const depots = [data, something]


export default function DashboardCard() {
  return (
    <Grid container spacing={2}>
      {/* <Grid lg={3} sm={6} xs={12}>
        <Budget diff={12} trend="up" sx={{ height: '100%' }} value="$24k" />
      </Grid> */}

      {exData.map(d => (
        <Grid size={{lg:3, sm:6, xs: 12}} key={d.type}>
          <TallyCard
            isExecutive
            data={d}
            sx={{ height: '100%' }}
          />
        </Grid>
      ))}

      {opData.map(d => (
        <Grid size={{lg: 3, sm:6, xs: 12}} key={d.type}>
          <ProductCard
            data={d}
            sx={{ height: '100%' }}
          />
        </Grid>
      ))}

      {progData.map(d => (
        <Grid size={{lg: 2.4, sm:6, xs: 12}} key={d.type}>
          <ProgressCard
            sx={{ height: '100%' }}
            data={d}
          />
        </Grid>
      ))}

      <Grid size={{ lg: 2.4, sm: 6, xs: 12 }} key={"LabeledProgressBar"}>
        <BulkBreakCard
          sx={{ height: '100%' }}
        />
      </Grid>

      {suborders.map(d => (
        <Grid size={{lg: 2.4, sm:6, xs: 12}} key={d.type}>
          <TallyCard
            data={d}
            sx={{ height: '100%' }}
          />
        </Grid>
      ))}

      {depots.map(d => (
        <Grid size={{lg: 2.4, sm:6, xs: 12}} key={d.type}>
          <ProductCard
            isTally
            data={d}
            sx={{ height: '100%' }}
          />
        </Grid>
      ))}
    </Grid>
  );
}
