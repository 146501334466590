import React from 'react'
import PropTypes from 'prop-types'
import { Box, TextField, Divider, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'


const CustomPasswordInput = ({ InputLabelProps = {}, ...props }) => {

  const [showPassword, setShowPassword] = React.useState(false)

  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      <TextField
        slotProps={{
          inputLabel: { ...InputLabelProps, shrink: true }
        }}
        {...props}
        type={showPassword ? 'text' : 'password'}
        fullWidth
      />
      <Divider sx={{orientation:"vertical", color: "red"}} />
      <IconButton onClick={() => { setShowPassword(!showPassword) }} sx={{ position: 'absolute', right: 0, mt: 1 }}>
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>

    </Box>

  );
}

CustomPasswordInput.propTypes = {
  InputLabelProps: PropTypes.object,
  props: PropTypes.any,
}

export default CustomPasswordInput
