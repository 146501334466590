import React from 'react';
import PropTypes from 'prop-types'

import { Typography, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';

import API from '../../data/api';

export default class AggregatedStatsComponent extends React.Component {
  static formatNumber(number) {
    return new Intl.NumberFormat().format(number);
  }

  constructor(props) {
    super(props);
    this.state = {
      statValues: {
        client_count: null,
        order_count: null,
        suborder_count: null,
        total_revenue: null,
        total_ulp93: null,
        total_ulp95: null,
        total_ppm50: null,
      },
    };
  }

  componentDidMount() {
    this.getAggregatedStats();
  }

  componentDidUpdate() {
    if (this.props.updateStats) {
      this.getAggregatedStats();
    }
  }

  getAggregatedStats = async () => {
    API.get('inventory/backoffice/aggregation')
      .then((res) => {
        if (res.status === 200) {
          console.log(`Aggregation endpoint [OK] = ${res.status}`);
          this.setState({
            statValues: {
              client_count: res.data.client_count,
              order_count: res.data.order_count,
              suborder_count: res.data.suborder_count,
              total_revenue: res.data.total_revenue,
              total_ulp93: res.data.total_ulp93,
              total_ulp95: res.data.total_ulp95,
              total_ppm50: res.data.total_ppm50,
            },
          });
          this.props.setUpdateStats(false);
        } else {
          console.log('There was an error retrieving aggregated stats');
        }
      })
    .catch((error) => {
        console.error('There was an error retrieving aggregated stats, ', error);
    });
  };

  render() {
    const labelStyle = {
      color: '#555',
    };

    const valueStyle = {
      color: '#000',
      fontWeight: 'bold',
    };

    const getColorStyle = (value) => ({
      color: value > 0 ? 'red' : 'green',
      fontWeight: 'bold',
    });

    return (
      <Box sx={{ flexGrow: 1 , mt: 4 }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 4, sm: 3, md: 2 }}>
            <Box mb={2}>
              <Typography variant="body2" style={labelStyle}>Orders</Typography>
              <Typography variant="body2" style={valueStyle}>{this.state.statValues.order_count}</Typography>
            </Box>
          </Grid>

          <Grid size={{ xs: 4, sm: 3, md: 2 }}>
            <Box mb={2}>
              <Typography variant="body2" style={labelStyle}>Suborders</Typography>
              <Typography variant="body2" style={valueStyle}>{this.state.statValues.suborder_count}</Typography>
            </Box>
          </Grid>

          <Grid size={{ xs: 4, sm: 3, md: 2 }}>
            <Box mb={2}>
              <Typography variant="body2" style={labelStyle}>Revenue</Typography>
              <Typography variant="body2" style={valueStyle}>
                R {AggregatedStatsComponent.formatNumber(this.state.statValues.total_revenue)}-00
              </Typography>
            </Box>
          </Grid>

          <Grid size={{ xs: 4, sm: 3, md: 2 }}>
            <Box mb={2}>
              <Typography variant="body2" style={labelStyle}>ULP 93 Orders</Typography>
              <Typography variant="body2" style={getColorStyle(this.state.statValues.total_ulp93)}>
                {AggregatedStatsComponent.formatNumber(this.state.statValues.total_ulp93)} Ltrs
              </Typography>
            </Box>
          </Grid>

          <Grid size={{ xs: 4, sm: 3, md: 2 }}>
            <Box mb={2}>
              <Typography variant="body2" style={labelStyle}>ULP 95 Orders</Typography>
              <Typography variant="body2" style={getColorStyle(this.state.statValues.total_ulp95)}>
                {AggregatedStatsComponent.formatNumber(this.state.statValues.total_ulp95)} Ltrs
              </Typography>
            </Box>
          </Grid>

          <Grid size={{ xs: 4, sm: 3, md: 2 }}>
            <Box mb={2}>
              <Typography variant="body2" style={labelStyle}>50 PPM Orders</Typography>
              <Typography variant="body2" style={getColorStyle(this.state.statValues.total_ppm50)}>
                {AggregatedStatsComponent.formatNumber(this.state.statValues.total_ppm50)} Ltrs
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

AggregatedStatsComponent.propTypes = {
  updateStats: PropTypes.bool.isRequired,
  setUpdateStats: PropTypes.func.isRequired,
};
