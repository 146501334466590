import React from 'react';
import moment from 'moment';
import { Paper, TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import API from '../../data/api';
import GlobalFilter from '../global-filter/GlobalFilter'; // Import GlobalFilter
import CustomTablePagination from '../custom/CustomTablePagination';

export default class DeliveredOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveredOrdersList: [],
      page: 1,
      records: 15,
      totalRecords: 1,
      totalPages: null,
      recordsList: [5, 10, 15, 20],
      filterString: '', // Add filterString to the state
    };
  }

  componentDidMount() {
    this.getDeliveredOrders(this.state.filterString);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filterString !== this.state.filterString) {
      this.getDeliveredOrders(this.state.filterString);
    }
  }

  getDeliveredOrders = async (finalQuery = '') => {
    const { records, page } = this.state;
    const conTemp = `inventory/backoffice/delivered_suborders?records=${records}&page=${page}${finalQuery}`;

    try {
      const res = await API.get(conTemp);
      if (res.status === 200) {
        const suborders = res.data.suborders;
        this.setState({
          deliveredOrdersList: suborders,
          totalPages: res.data.total_pages,
          totalRecords: res.data.total_suborders,
        });
      } else {
        console.error("Error fetching delivered orders:", res.status);
      }
    } catch (error) {
      console.error("Error fetching delivered orders:", error);
    }
  }

  handleFilterChange = (filterString) => {
    this.setState({ filterString });
  };

  nextPage = (action) => {
    const limit = this.state.totalPages;
    this.setState((prevState) => {
      let currentPage = prevState.page;

      if (action === 'next') {
        currentPage = Math.min(currentPage + 1, limit);
      } else {
        currentPage = Math.max(currentPage - 1, 1);
      }

      return { page: currentPage };
    }, () => {
      this.getDeliveredOrders(this.state.filterString);
    });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState(() => {
      return { records: parseInt(event.target.value, 10), page: 1 };
    }, () => {
      this.getDeliveredOrders(this.state.filterString);
    });
  }

  render() {
    return (
      <Paper>
        <GlobalFilter sx={{ my: 1 }} onFilterChange={this.handleFilterChange} />

        <TableContainer  sx={{ mt: 2, width: '100%', overflowX: { xs: 'scroll', sm: 'auto' } }} >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Order Number</TableCell>
              <TableCell align="right">Client Name</TableCell>
              <TableCell align="right">Product</TableCell>
              <TableCell align="right">Volume</TableCell>
              <TableCell align="right">Delivery Location</TableCell>
              <TableCell align="center">Created Date</TableCell>
              <TableCell align="right">Scheduled Delivery Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.deliveredOrdersList.map((row) => (
              <TableRow
                key={row.suborder_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: 'rgba(39,226,245,0.27)' } }}>
                <TableCell component="th" scope="row">{row.suborder_id}</TableCell>
                <TableCell align="right">{row.company_name}</TableCell>
                <TableCell align="right">{row.fuel_type}</TableCell>
                <TableCell align="right">{row.volume}</TableCell>
                <TableCell align="right">{row.delivery_location}</TableCell>
                <TableCell align="right">{moment(row.created_datetime).format('YYYY/MM/DD - HH:mm:ss')}</TableCell>
                <TableCell align="right">{moment(row.delivery_scheduled_datetime).format('YYYY/MM/DD - HH:mm:ss')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>

        <CustomTablePagination
          rowsPerPage={this.state.records}
          recordsList={this.state.recordsList}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
          handleNextPage={this.nextPage.bind(this)}
          currentPage={this.state.page}
          totalPages={this.state.totalPages}
        />
      </Paper>
    );
  }
}
