import { Helmet } from 'react-helmet-async';
import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { Container, Typography, Button, Modal, Box, FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import Iconify from '../components/iconify';
import API from '../data/api'; // Import the API module
import CustomTextField from 'src/components/custom/CustomTextField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ReportsPage() {
  const [documents, setDocuments] = useState([]);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const fetchDocuments = useCallback(async () => {
    try {
      const response = await API.get(`sharedfiles/view_all?page=${page}&records=10${searchTerm ? `&search_term=${searchTerm}` : ''}`);
      setDocuments(response.data.files);
      setTotalPages(Math.ceil(response.data.total_files / 10));
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  }, [page, searchTerm]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const handleView = (document) => {
    setSelectedDocument(document);
    setOpenViewModal(true);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
    setSelectedDocument(null);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    setOpenUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
    setSelectedFile(null);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;
  
    const formData = new FormData();
    formData.append('file', selectedFile);
  
    try {
      // Adjust the API call based on your backend URL
      await API.put('sharedfiles/upload?service_provider=aws', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      setOpenUploadModal(false);
      fetchDocuments(); // Refresh the document list after upload
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleDelete = async (fileName) => {
    try {
      await API.delete(`sharedfiles/delete_single_file/aws/${fileName}`);
      fetchDocuments(); // Refresh the document list after deletion
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (direction) => {
    if (direction === 'prev' && page > 0) {
      setPage(page - 1);
    } else if (direction === 'next' && page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Reports
        </Typography>
        <Button variant="contained" sx={{ mb: 2 }} onClick={handleUpload}>
          Upload Document
        </Button>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <CustomTextField
            id="search"
            label="Search Documents"
            variant="outlined"
            onChange={handleSearchChange}
          />
        </FormControl>

        <TableContainer component={Paper}>
          <Table aria-label="documents table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documents.map((document) => (
                <TableRow key={document.name}>
                  <TableCell component="th" scope="row">
                    <Iconify icon={'ion:documents-sharp'} sx={{ fontSize: '36px', verticalAlign: 'middle', mr: 2 }} />
                    {document.name}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton color="primary" onClick={() => handleView(document)}>
                      <Iconify icon={'mdi:eye'} />
                    </IconButton>
                    <IconButton color="secondary" href={document.url} target="_blank" download>
                      <Iconify icon={'mdi:download'} />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleDelete(document.name)}>
                      <Iconify icon={'mdi:delete'} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" onClick={() => handlePageChange('prev')} disabled={page === 0}>
            Previous
          </Button>
          <Typography>Page {page + 1} of {totalPages}</Typography>
          <Button variant="contained" onClick={() => handlePageChange('next')} disabled={page === totalPages - 1}>
            Next
          </Button>
        </Box>

        <Modal open={openViewModal} onClose={handleCloseViewModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={style}>
            {selectedDocument && (
              <>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {selectedDocument.name}
                </Typography>
                <iframe src={selectedDocument.url} width="100%" height="600px" title={selectedDocument.name} />
              </>
            )}
          </Box>
        </Modal>

        <Modal open={openUploadModal} onClose={handleCloseUploadModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Upload Document
            </Typography>
            <input type="file" onChange={handleFileChange} />
            <Button variant="contained" sx={{ mt: 2 }} onClick={handleFileUpload}>
              Upload
            </Button>
          </Box>
        </Modal>
      </Container>
    </>
  );
}
