import React, { Component } from 'react';
import moment from 'moment';
import { Grid, FormControl, Button } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types'; // Import PropTypes
import CustomTextField from '../custom/CustomTextField';

class GlobalFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: 'YYYY/MM/DD HH:mm:ss',
      endDate: 'YYYY/MM/DD HH:mm:ss',
      search: '',
    };
  }

  handleFilterChange = (name, value) => {
    this.setState({ [name]: value });
  };

  submitFilter = () => {
    const { startDate, endDate, search } = this.state;
    const queryParam = '&';
    let filterString = queryParam;

    const hasCustomStartDate = startDate !== 'YYYY/MM/DD HH:mm:ss';
    const hasCustomEndDate = endDate !== 'YYYY/MM/DD HH:mm:ss';
    const hasSearchTerm = search !== '';

    if (!hasCustomStartDate && !hasCustomEndDate && !hasSearchTerm) {
      // Default case when no search values are provided
      // do nothing  
    } else {
      // Binary search values for start, end, and search term
      if (hasCustomStartDate && hasCustomEndDate) {
        filterString += `time_start=${this.state.startDate}&time_end=${this.state.endDate}`;
      }
      if (hasCustomStartDate && !hasCustomEndDate) {
        if (hasCustomEndDate === false) {
          filterString += `time_start=${this.state.startDate}`;
        }
      }
      if (!hasCustomStartDate && hasCustomEndDate) {
        if (hasCustomStartDate === false) {
          filterString += `end_start=${this.state.startDate}`;
        }
      }
      if (hasSearchTerm) {
        if (filterString !== queryParam) {
          // If there are already parameters, add '&' before adding search term
          filterString += '&';
        }
        filterString += `search_term=${this.state.search}`;
      }
    }

    // Pass the filterString to each child component
    this.props.onFilterChange(filterString);
  };

  render() {
    const { startDate, endDate, search } = this.state;

    return (
      <Grid container alignItems={'right'}>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
              <DateTimePicker
                label="Start Date"
                onChange={(e) => { this.handleFilterChange('startDate', moment(e.$d).format('YYYY/MM/DD HH:mm:ss')) }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>

        <Grid item xs={4} sx={{ mx: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
              <DateTimePicker
                label="End Date"
                onChange={(e) => { this.handleFilterChange('endDate', moment(e.$d).format('YYYY/MM/DD HH:mm:ss')) }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth>
            <CustomTextField
              id="search"
              label="Search Term"
              variant="outlined"
              sx={{ mt: 1 }}
              onChange={(e) => { this.handleFilterChange('search', e.target.value) }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={1}>
          <FormControl>
            <Button
              variant='contained'
              sx={{ mt: 2, ml: 1 }}
              onClick={this.submitFilter}
            >
              Filter
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

GlobalFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
};

export default GlobalFilter;
