import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import API from '../data/api';
import {
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
} from '../sections/@dashboard/app';

export default function DashboardAppPage() {
  const [pieChartData, setPieChartData] = useState(null);
  const [monthlyReportData, setMonthlyReportData] = useState(null);
  const [cardData, setCardData] = useState({ approved: 0, declined: 0, delivered: 0, pastMonthRevenue: 0 });

  useEffect(() => {
    // Retrieve the string from localStorage

    const addUser = async () => {
      const userInfoString = localStorage.getItem('user_info');
      console.log("GET USER INFO");

      if (userInfoString) {
        const userInfo = JSON.parse(userInfoString);
        console.log('User Info:', userInfo);

        const endpoint = 'auth/add_new_user';
        const registrationId = userInfo["registration_id"];
        const keycloakId = userInfo["sub"];
        const companyId = userInfo["company_id"];

        try {
          const response = await API.post(endpoint, {
            registration_id: registrationId,
            keycloak_id: keycloakId,
            company_id: companyId
          });

          console.log(response.data);
        } catch (error) {
          console.error('Failed to add new user:', error.message);
        }
      } else {
        console.log('No user_info found in localStorage.');
      }
    };

    
    
    // Fetch data for charts and other components
    const fetchChartData = async () => {
      try {
        const pieChartResponse = await API.get('analytics/product_revenue_pie_chart');
        setPieChartData(pieChartResponse.data);
      } catch (error) {
        console.error('Error fetching pie chart data:', error);
      }
    };
  
    const fetchMonthlyReportData = async () => {
      try {
        const monthlyReportResponse = await API.get('analytics/combined_rolling_annual');
        setMonthlyReportData(monthlyReportResponse.data);
      } catch (error) {
        console.error('Error fetching monthly report data:', error);
      }
    };
  
    const fetchCardData = async () => {
      try {
        const cardResponse = await API.get('analytics/cards');
        setCardData(cardResponse.data.cards);
      } catch (error) {
        console.error('Error fetching card data:', error);
      }
    };
  
    fetchChartData();
    fetchMonthlyReportData();
    fetchCardData();
    addUser();
  }, []);
  

  const formatNumber = (num) => (num !== null ? num.toFixed(2) : '0.00');

  const pieChartLabels = pieChartData ? pieChartData.data.labels : [];
  const pieChartValues = pieChartData ? pieChartData.data.datasets[0].data.map(formatNumber) : [];
  const pieChartColors = pieChartData ? pieChartData.data.datasets[0].backgroundColor : [];

  const currentDate = new Date();
  const monthlyReportLabels = monthlyReportData
    ? monthlyReportData.data.labels
    : Array.from({ length: 12 }, (_, i) => {
        const date = new Date();
        date.setMonth(currentDate.getMonth() - (11 - i));
        return date.toLocaleString('default', { month: 'short', year: 'numeric' });
      });

  const monthlyReportRevenue = monthlyReportData
    ? monthlyReportData.data.datasets[0].data.map(formatNumber)
    : [];
  const monthlyReportLossGain = monthlyReportData
    ? monthlyReportData.data.datasets[1].data.map(formatNumber)
    : [];

  const tooltipFormatter = (tooltipItem) => `${tooltipItem.value} BWP`;

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Financial Summary View
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Approved Orders" total={cardData.approved} icon={'ant-design:check-circle-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Declined Orders" total={cardData.declined} color="error" icon={'ant-design:close-circle-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Delivered Orders" total={cardData.delivered} color="warning" icon={'ant-design:car-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Revenue Expected" total={cardData.pastMonthRevenue} color="info" icon={'ant-design:shopping-filled'} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Rolling Annual Revenue/(Loss/Gain)"
              subheader="Monthly revenue tracking"
              chartLabels={monthlyReportLabels}
              chartData={[
                {
                  name: 'Revenue Generated',
                  type: 'area',
                  fill: 'solid',
                  data: monthlyReportRevenue,
                },
                {
                  name: 'Loss/Gain Revenue',
                  type: 'area',
                  fill: 'gradient',
                  data: monthlyReportLossGain,
                },
              ]}
              tooltipFormatter={tooltipFormatter}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Product Revenue"
              chartData={pieChartValues.map((value, index) => ({
                label: pieChartLabels[index],
                value: parseFloat(value), // Ensure the values are numbers
              }))}
              chartColors={pieChartColors}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
